import { createSignal } from 'solid-js';
import type { AuthUser } from '~/libs/api-client';
import { ensureClientSide } from '~/utils';

const [getter, setter] = createSignal<AuthUser | undefined>();

const getUser = getter;

const setUser = (value?: AuthUser) => {
  ensureClientSide(
    'setUser can only be executed in browser. Signals are only allowed to be used on the client side.',
  );
  setter(value);
};

export { getUser, setUser };
