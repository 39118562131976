export const isClientSide = () => typeof window !== 'undefined';
export const isServerSide = () => typeof window === 'undefined';

export function ensureClientSide(errorMessage?: string) {
  if (isServerSide()) {
    throw new Error(
      errorMessage ?? 'This code is only allowed to be used on the client side',
    );
  }
}
